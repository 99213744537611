import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 80px;
`;

export const FooterContent = styled.div`
  padding: 10px;
  margin-right: 25px;
`;
export const FooterText = styled.p`
  color: #fff;
  font-size: 0.8rem;
`;
