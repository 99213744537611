import React from "react";
import { StepItem, StepImg, StepTitle, StepText } from "./ContactElements";

function Step({ img, title, text }) {
  return (
    <StepItem>
      <StepImg src={img} layout="fill" objectFit="contain" />
      <StepTitle>{title}</StepTitle>
      <StepText>{text}</StepText>
    </StepItem>
  );
}

export default Step;
