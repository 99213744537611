import React from "react";
import Step from "./Step";
import { ContactContainer, StepGrid } from "./ContactElements";
import Step1 from "../../assets/img/step1.svg";
import Step2 from "../../assets/img/step2.svg";
import Step3 from "../../assets/img/step3.svg";

function Contact() {
  const steps = [
    {
      img: Step1,
      title: "Step 1",
      text:
        "Click 'Get a Quote' and fill out your contact details, along with a brief description on the project(s) that you would like our hep with.",
    },
    {
      img: Step2,
      title: "Step 2",
      text:
        "We will call you to confirm any additional required information. If required we will schedule an in person consultation.",
    },
    {
      img: Step3,
      title: "Step 3",
      text:
        "We will provide you with a competitive quote with a full breakdown of any anticipated costs.",
    },
  ];

  return (
    <ContactContainer>
      <StepGrid>
        {steps.map((step) => {
          return <Step img={step.img} title={step.title} text={step.text} />;
        })}
      </StepGrid>
    </ContactContainer>
  );
}

export default Contact;
