import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Contact from "../components/Contact/Contact";
import ContentBlock from "../components/Content/ContentBlock";
import Reviews from "../components/Reviews/Reviews";
import Services from "../components/Services/Services";
import ImageSlider from "../components/ImageSlider/ImageSlider";
import RTT from "../components/ReturnToTop/RTT";
import GetQuote from "../components/Quote/GetQuote";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/SIdebar";

function Home() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <Contact />
      <main>
        <ContentBlock />
        <Reviews />
        <Services />
        <ImageSlider />
        <GetQuote />
      </main>
      <Footer />

      {showButton && <RTT scrollToTop={scrollToTop} />}
    </>
  );
}

export default Home;
