import React from "react";
import {
  ReviewBox,
  ReviewContainer,
  ReviewsContainer,
  ReviewsTitle,
} from "./ReviewsElements";

function Reviews() {
  return (
    <ReviewsContainer>
      <ReviewsTitle>What our Customers Say</ReviewsTitle>
      <ReviewBox>
        <ReviewContainer></ReviewContainer>
      </ReviewBox>
    </ReviewsContainer>
  );
}

export default Reviews;
