import React from "react";
import {
  FormSection,
  FormSubtext,
  QuoteBox,
  QuoteContainer,
  SubPara,
  TitleSection,
  TitleText,
} from "./QuoteElements";
import QuoteForm from "./QuoteForm";

function GetQuote() {
  return (
    <>
      <QuoteContainer id="quote">
        <QuoteBox>
          <TitleSection>
            <TitleText>Get a Quote</TitleText>
          </TitleSection>
          <FormSubtext>
            <SubPara>
              Please fill out this form with as much information as possible so
              that we can try and assess your project virtually.
            </SubPara>
            <SubPara>
              Here's an example. You want a TV hung on your wall right? That's
              great! We can do that. Problem is, without enough information we
              don't know:
              <ul>
                <li>Is your wall concrete? Drywall? Shiplap?</li>
                <li>
                  Are there any obstacles? E.g. fireplace, existing built in
                  cabinets, unit?
                </li>
                <li>Do you already have mounting hardware?</li>
                <li>What is the size of the TV?</li>
                <li>
                  Is there any existing hardware that needs to be removed?
                </li>
              </ul>
              By being as informative as possible, we can aim give you an
              accurate quote first time and book in to help you with your
              project as soon as possible. If we require any further
              information, we will contact you using your preferred method as
              specified.
            </SubPara>
          </FormSubtext>
          <FormSection>
            <QuoteForm />
          </FormSection>
        </QuoteBox>
      </QuoteContainer>
    </>
  );
}

export default GetQuote;
