import React from "react";
import { GiDrill } from "react-icons/gi";
import { FaHardHat, FaHammer } from "react-icons/fa";
import {
  ContentWrapper,
  TitleText,
  GridWrapper,
  GridElement,
  IconStyling,
  ElementTitle,
  ElementText,
} from "./ContentElements";

function ContentBlock() {
  return (
    <ContentWrapper id="about">
      <TitleText>
        We specialize in interior and exterior repairs and remodels
      </TitleText>
      <GridWrapper>
        <GridElement>
          <IconStyling>{<GiDrill />}</IconStyling>

          <ElementTitle>Trustworthy</ElementTitle>
          <ElementText>
            We know how important your home is to you. Every time we attend your
            property, we ensure that we treat your property with the utmost care
            and respect. You can trust that we will always make sure your home
            is 100% protected when we are working. You can also trust that we
            will always strive to give you the best cost, the best service and
            the best experience.
          </ElementText>
        </GridElement>
        <GridElement>
          <IconStyling>{<FaHardHat />}</IconStyling>
          <ElementTitle>Perfectionism</ElementTitle>
          <ElementText>
            We're not satisfied unless you're satisfied. We pride ourselves on
            providing the best quality work without cutting any corners. If
            something isn't how you expected, let us know how we can make it
            right. We won't rush to finish your project - that isn't how we
            operate. We'll make sure that any work we carry out receives our
            full care and attention so that it's perfect every time.
          </ElementText>
        </GridElement>
        <GridElement>
          <IconStyling>{<FaHammer />}</IconStyling>
          <ElementTitle>All Jobs Welcome</ElementTitle>
          <ElementText>
            There's no such thing as a job too small. If you need help, reach
            out to us today and we'll let you know what we can do. From hanging
            a TV on the wall, changing a light fixture or changing door handles
            - to full bathroom renovations, quality flooring installs and
            complete room remodelling. Contact us today, you won't be
            disappointed!
          </ElementText>
        </GridElement>
      </GridWrapper>
    </ContentWrapper>
  );
}

export default ContentBlock;
