import React from "react";
import {
  ServiceItemContainer,
  ServiceItemImg,
  ServiceWrapper,
  ServiceTitle,
  ServiceText,
} from "./ServiceElements";

function ServiceItem({ img, title, text }) {
  return (
    <ServiceItemContainer>
      <ServiceItemImg>{img}</ServiceItemImg>
      <span></span>
      <ServiceWrapper>
        <ServiceTitle>{title}</ServiceTitle>
        <ServiceText>{text}</ServiceText>
      </ServiceWrapper>
    </ServiceItemContainer>
  );
}

export default ServiceItem;
