import React from "react";
import { FooterContainer, FooterContent, FooterText } from "./FooterElements";

function Footer() {
  return (
    <>
      <FooterContainer>
        <FooterContent>
          <FooterText>Copyright &copy; Port Union Handyman 2022 </FooterText>
        </FooterContent>
      </FooterContainer>
    </>
  );
}

export default Footer;
