import img1 from "../../assets/img/img1.jpg";
import img2 from "../../assets/img/img2.jpg";
import img3 from "../../assets/img/img3.jpg";
import img4 from "../../assets/img/img4.jpg";

export const SliderData = [
  {
    id: 1,
    image: img1,
    description: "Test",
  },
  {
    id: 2,
    image: img2,
    description: "Test",
  },
  {
    id: 3,
    image: img3,
    description: "Test",
  },
  {
    id: 4,
    image: img4,
    description: "Test",
  },
];
