import styled from "styled-components";
import QuotesBG from "../../assets/img/quotes.jpg";

export const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, #ffffffba, #ffffffcc), url(${QuotesBG});
  background-size: cover;
  background-position: center;
  align-items: center;
  z-index: 0;
  width: 100%;
`;

export const QuoteBox = styled.div`
  width: 60%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  margin: 25px 0;
  z-index: 1;
  border-radius: 0.5rem;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  border-top-left-radius: 0.5rem; /* 8px */
  border-top-right-radius: 0.5rem;
  align-items: center;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
`;

export const TitleText = styled.h1`
  color: #fff;
  margin-left: 15px;
  font-size: 2rem;
  letter-spacing: 2px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
`;

export const FormSubtext = styled.div`
  display: block;
  flex-direction: row;
  width: 100%;
  text-align: left;
  padding: 0 30px;
  margin-top: 20px;
  margin-bottom: 0;
}
`;

export const SubPara = styled.p`
  color: #000;
  font-size: 0.8rem;
  margin-bottom: 10px;

  ul {
    margin-top: 15px;
    margin-left: 25px;
    margin-bottom: 15px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FormSection = styled.div`
  width: 100%;
  padding: 30px;
  row-gap: 2rem;
`;

export const FormLabel = styled.label`
  font-size: 0.8rem;
  display: block;
  margin-bottom: 5px;
`;

export const TextField = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  resize: vertical;
  width: 100%;
`;

export const TextArea = styled.textarea`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  resize: vertical;
  width: 100%;
  margin: 0;
`;

export const Select = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  resize: vertical;
  width: 100%;
  margin: 0;
`;

export const Submit = styled.input`
  padding: 15px 10px;
  background-color: #1673dc;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  resize: vertical;
  width: 20%;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  &:hover {
    background-color: #162e86;
    transition: 0.5s ease-in-out;
  }
`;

export const ResponseMessage = styled.div`
  padding: 15px 10px;
  background-color: #2ecc71;
  width: 100%;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #fff;
`;

export const ProcessingDiv = styled.div`
  text-align: center;
  height: 20px;
`;

export const RecaptchaBox = styled.div`
  margin-top: 20px;
`;
