import React from "react";
import {
  InfoContainer,
  InfoLeft,
  InfoH1,
  InfoText,
  NavBtnLink,
  InfoSectImg,
} from "./HeaderStyle";
import InfoImg from "../../assets/img/construction.svg";

function InfoSection() {
  return (
    <InfoContainer>
      <InfoLeft>
        <InfoH1>Affordable.</InfoH1>
        <InfoH1>Quality.</InfoH1>
        <InfoH1>Fast.</InfoH1>
        <InfoText>
          Take the stress out of your next home renovation project with the Port
          Union Handyman.
        </InfoText>
        <NavBtnLink
          to="quote"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
        >
          Get a Quote
        </NavBtnLink>
      </InfoLeft>
      <InfoSectImg src={InfoImg} alt="header graph" />
    </InfoContainer>
  );
}

export default InfoSection;
