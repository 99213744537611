import styled from "styled-components";
import { BiChevronsUp } from "react-icons/bi";

export const ToTopButton = styled(BiChevronsUp)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  font-size: 0.9rem;
  width: 45px;
  height: 45px;
  background: #fff;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:hover {
    background: #1673dc;
    transition: 0.5s ease;
    color: #fff;
    border: 1px solid #fff;
  }
`;
