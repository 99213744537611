import React, { useState } from "react";
import { SliderData } from "./SliderData";
import {
  LeftArrow,
  RightArrow,
  SlideImage,
  Slider,
  LeftSlider,
  RightSlider,
  SliderSection,
  LeftSideTitle,
  LeftSideText,
} from "./SliderElements";

function ImageSlider() {
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
    <SliderSection id="work">
      <Slider>
        <LeftSlider>
          <LeftSideTitle>Check out Some of our work</LeftSideTitle>
          <LeftSideText>
            <p>
              We've put together some photographs of work that we've done for
              previous satisfied customers. Some examples of what we did:
            </p>
            <ul>
              <li>
                Dig up and clearance of existing backyard. Installation of patio
                slabs and black stone border.
              </li>
              <li>
                Complete nursery remodel. Creation of feature wall, removal of
                textured ceiling and installation of new fixtures.
              </li>
              <li>Unfinished basement full conversion to a home theatre.</li>
              <li>Installation of a bar in finished basement.</li>
            </ul>
            <p>
              Maybe you've been putting off getting those new blinds installed.
              Or what about turning that spare bedroom into a luxury work from
              home space? Kids moved out and now you want a home gym? We can
              even take that cold unfinished basement and turn it into a whole
              new living space for you and your family.
            </p>
          </LeftSideText>
        </LeftSlider>
        <RightSlider>
          <LeftArrow onClick={prevSlide} />
          <RightArrow onClick={nextSlide} />

          {SliderData.map((slide, index) => {
            return (
              <div
                className={index === current ? "slide active" : "slide"}
                key={index}
              >
                {index === current && (
                  <>
                    <SlideImage src={slide.image} alt="travel" />
                  </>
                )}
              </div>
            );
          })}
        </RightSlider>
      </Slider>
    </SliderSection>
  );
}

export default ImageSlider;
