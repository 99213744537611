import React from "react";
import {
  HeaderContainer,
  Nav,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./HeaderStyle";
import { FaBars } from "react-icons/fa";
import Logo from "../../assets/img/logo.png";
import InfoSection from "./InfoSection";
import { animateScroll as scroll } from "react-scroll";

const Header = ({ toggle }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <HeaderContainer>
        <Nav>
          <NavLogo src={Logo} alt="logo" onClick={toggleHome} />
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                activeClass="active"
              >
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                activeClass="active"
              >
                Services
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="work"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                activeClass="active"
              >
                Our Work
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="quote"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                activeClass="active"
              >
                Quote
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink
              to="quote"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              a
            >
              Get a Quote
            </NavBtnLink>
          </NavBtn>
        </Nav>
        {/*Lower Section*/}
        <InfoSection />
      </HeaderContainer>
    </>
  );
};

export default Header;
