import styled from "styled-components";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

export const SliderSection = styled.div`
  display: flex;
  justify-content: center;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
`;

export const Slider = styled.div`
  width: 80%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    width: 98%;
  }
`;

export const LeftSlider = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 30px;
  padding: 30px;
  border-radius: 10px;

  @media screen and (max-width: 1200px) {
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    width: 99%;
    margin-right: 0;
    padding: 10px;
  }
`;

export const LeftSideTitle = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 5px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: left;

  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 2rem;
  }
`;

export const LeftSideText = styled.p`
  color: #fff;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  margin: 25px 0;

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 800px) {
    left: 12%;
  }
`;

export const RightSlider = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const LeftArrow = styled(FaArrowAltCircleLeft)`
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 768px) {
    left: 2%;
  }

  @media screen and (max-width: 800px) {
    left: 12%;
  }
`;
export const RightArrow = styled(FaArrowAltCircleRight)`
  position: absolute;
  top: 50%;
  right: 5%;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 768px) {
    right: 2%;
  }

  @media screen and (max-width: 800px) {
    right: 12%;
  }
`;

export const SlideImage = styled.img`
  width: 500px;
  border-radius: 10px;
`;
