import styled from "styled-components";

export const ContactContainer = styled.div`
  background: #fff;
  width: 65%;
  margin: -80px auto 0;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-top-left-radius: 0.5rem; /* 8px */
  border-top-right-radius: 0.5rem;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const StepGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  color: #000;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const StepItem = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  margin: 30px 0;
  cursor: pointer;

  &:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.05);
  }
`;

export const StepImg = styled.img`
  position: relative;
  height: 80px;
`;

export const StepTitle = styled.p`
  font-size: 0.8rem;
  margin-top: 10px;
  font-weight: 700;
`;

export const StepText = styled.p`
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 0 30px;
`;
