import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  FormLabel,
  TextArea,
  TextField,
  Select,
  Submit,
  ResponseMessage,
  ProcessingDiv,
  RecaptchaBox,
} from "./QuoteElements";
import { Grid } from "@mui/material";
import { Bars } from "react-loading-icons";
import ReCAPTCHA from "react-google-recaptcha";

function QuoteForm() {
  const form = useRef();

  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [toSend, setToSend] = useState({
    full_name: "",
    email: "",
    telephone: "",
    address: "",
    description: "",
    contactMeth: "Email",
  });

  function sendEmail(e) {
    setProcessing(true);
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_FORMIK_SERVICE_ID,
        process.env.REACT_APP_FORMIK_TEMPLATE_ID,
        toSend,
        process.env.REACT_APP_FORMIK_USER_ID
      )
      .then(
        (result) => {
          setProcessing(false);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  function handleChange(value) {
    setToSend({ ...toSend, [value.target.name]: value.target.value });
  }

  function onChange(value) {
    setToSend({ ...toSend, "g-recaptcha-response": value });
  }

  return (
    <>
      {submitted ? (
        <Grid item xs={12}>
          <ResponseMessage>
            Thank you! We will contact you within 24 hours.
          </ResponseMessage>
        </Grid>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          <Grid container spacing={2}>
            <Grid item xs={12} s={7}>
              <FormLabel for="full_name">Full Name</FormLabel>
              <TextField
                type="text"
                name="full_name"
                id="full_name"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} s={5}>
              <FormLabel for="email">Email</FormLabel>
              <TextField
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} s={6}>
              <FormLabel for="telephone">Telephone</FormLabel>
              <TextField
                type="text"
                name="telephone"
                id="telephone"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item s={6}></Grid>
            <Grid item xs={12}>
              <FormLabel for="address">Address</FormLabel>
              <TextField
                type="text"
                name="address"
                id="address"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel for="description">Description of Job</FormLabel>
              <TextArea
                type="textarea"
                name="description"
                id="description"
                placeholder="Please provide a description of the work that you need done. Be as detailed as possible!"
                rows={5}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={11} md={6}>
              <FormLabel for="contactMeth">
                Would you prefer to be contacted by phone or email?
              </FormLabel>
              <Select
                name="contactMeth"
                id="contactMeth"
                defaultValue="Email"
                onChange={handleChange}
              >
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
              </Select>
            </Grid>
            <Grid item xs={0} md={6}></Grid>
            <Grid item xs={12}>
              <RecaptchaBox>
                <ReCAPTCHA
                  sitekey="6LfoRnsfAAAAAI_qglrbPMiVtLcvv8oUFz-P58a1"
                  onChange={onChange}
                />
              </RecaptchaBox>
            </Grid>
            {processing ? (
              <Grid item xs={12}>
                <ProcessingDiv>
                  <Bars fill="#162e86" width={50} />
                </ProcessingDiv>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <Submit type="submit" value="Get Quote" />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}

export default QuoteForm;
