import styled from "styled-components";

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

export const HeaderText = styled.h1`
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 0.05em;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  width: 100%;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
  text-align: center;
  padding: 5px;
  margin-bottom: 40px;
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  width: 75%;
  row-gap: 2rem;

  @media screen and (max-width: 1300px) {
    width: 90%;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const SubTextContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 70%;
`;

export const SubText = styled.p`
  text-align: right;
  margin-top: 10px;
  font-size: 0.7rem;
  color: #142d83;
`;

export const ServiceItemContainer = styled.div`
  position: relative;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
  box-sizing: border-box;
  padding: 0 15px;
  transition: 0.5s;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 140px;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  span {
    position: absolute;
    top: 0;
    left: -110%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transition: 1s;
    color: #fff;
    transform: skewX(10deg);
  }

  &:hover span {
    left: 110%;
  }
`;

export const ServiceItemImg = styled.div`
  display: flex;
  padding: 0 15px;
  font-size: 3rem;
  color: #fff;
`;

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ServiceTitle = styled.h1`
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1rem;
  color: #fff;
  text-align: left;
`;

export const ServiceText = styled.p`
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  margin-top: 2px;
  padding-right: 15px;
  color: #fff;
  text-align: left;
`;
