import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const TitleText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #142d83;
  max-width: 80%;
  padding-bottom: 25px;
  line-height: normal;
  border-bottom: 2px solid #142d83;

  @media screen and (max-width: 1300px) {
    text-align: center;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  margin-top: 50px;
  width: 80%;
`;

export const GridElement = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const IconStyling = styled.span`
  margin-bottom: 20px;
  font-size: 2rem;
  color: #142d83;
`;

export const ElementTitle = styled.div`
  display: flex;
  font-size: 0.9rem;
  font-weight: 700;
`;

export const ElementText = styled.div`
  display: flex;
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 15px;
`;
