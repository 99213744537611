import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const HeaderContainer = styled.div`
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
  display: flex;
  height: 550px;
  flex-direction: column;
  z-index: 0;
`;

export const Nav = styled.nav`
  display: flex;
  height: 120px;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
`;

export const NavLogo = styled.img`
  position: relative;
  height: 120px;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-top: 10px;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: -3%;
  text-align: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.025em;
  font-family: "Poppins", sans-serif;

  &:hover {
    color: #fec516;
    border-bottom: 2px solid #fec516;
  }

  &.active {
    border-bottom: 3px solid #fec516;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkS)`
  border-radius: 50px;
  background: #fec516;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #eda307;
  }
`;

export const Quote = styled(LinkR)`
  border-radius: 50px;
  background: #fec516;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #eda307;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
export const InfoLeft = styled.div`
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 80%;
    text-align: center;
    margin-top: 10px;
  }
`;
export const InfoH1 = styled.h1`
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-size: 3rem;
  letter-spacing: 0.025em;
  line-height: 1;
  margin: 0px;
  padding: 0px;

  &:last-of-type {
    color: #fec516;
  }
`;

export const InfoText = styled.p`
  color: #fff;
  font-size: 0.9rem;
  margin: 25px 0;
`;

export const InfoSectImg = styled.img`
  height: 220px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
