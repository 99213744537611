import React from "react";
import {
  GiHandSaw,
  GiClawHammer,
  GiCrackedGlass,
  GiHammerNails,
  GiShower,
} from "react-icons/gi";
import { FiPackage } from "react-icons/fi";
import { MdOutlineLight } from "react-icons/md";
import { FaToilet } from "react-icons/fa";
import ServiceItem from "./ServiceItem";
import {
  SubTextContainer,
  ServiceContainer,
  ServicesGrid,
  HeaderText,
  SubText,
} from "./ServiceElements";

const services = [
  {
    img: <GiHandSaw />,
    title: "Carpentry",
    text:
      "We offer various carpentry services ranging from custom built in wardrobes / closets, chests of drawers, bookshelves and more!",
  },
  {
    img: <GiClawHammer />,
    title: "Framing",
    text:
      "Putting up walls, taking them down. Adding a window, removing a window. Building a closet, tearing one out. We can do it.",
  },
  {
    img: <GiCrackedGlass />,
    title: "Drywall Repair",
    text:
      "From tiny holes to human sized accidents, we'll make it good as new.",
  },
  {
    img: <FiPackage />,
    title: "Flooring Installation",
    text:
      "It looks easy until you try it. If you want a professional looking floor, get in touch.",
  },
  {
    img: <GiShower />,
    title: "Tiling",
    text:
      "Tired of your old outdated backsplash? Shower looking a little grim? Contact us today and we'll take care of it.",
  },
  {
    img: <GiHandSaw />,
    title: "Caulking",
    text:
      "Neglected your shower and now your caulking is covered in the dreaded black mold? Let us know and we can fix it.",
  },
  {
    img: <MdOutlineLight />,
    title: "Light Fixtures / Switches",
    text:
      "If you're tired of your old dated light switches or light fixtures, we can help you install new ones!",
  },
  {
    img: <GiHammerNails />,
    title: "Trimming",
    text:
      "We cover all trim jobs from full tear out an install to fixing minor damage to existing trim. ",
  },
  {
    img: <FaToilet />,
    title: "Installations",
    text:
      "Want to finally mount that TV on the wall? Need to change out that old failing toilet? Maybe you have a fridge. We'll install it.",
  },
];

function Services({ img, title, text }) {
  return (
    <ServiceContainer id="services">
      <HeaderText>Our Services</HeaderText>
      <ServicesGrid>
        {services.map((service) => {
          return (
            <ServiceItem
              img={service.img}
              title={service.title}
              text={service.text}
            />
          );
        })}
      </ServicesGrid>
      <SubTextContainer>
        <SubText>
          And much more! Get in touch with details of your project today.
        </SubText>
      </SubTextContainer>
    </ServiceContainer>
  );
}

export default Services;
