import styled from "styled-components";

export const ReviewsContainer = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(21, 115, 219);
  background: radial-gradient(
    circle,
    rgba(21, 115, 219, 1) 0%,
    rgba(20, 45, 131, 1) 100%
  );
`;

export const ReviewsTitle = styled.h1`
  font-weight: 700;
  font-size: 2.2rem;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 5px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: left;
  margin-bottom: 30px;

  @media screen and (max-width: 800px) {
    text-align: center;
  }
`;

export const ReviewBox = styled.div`
  display: flex;
  height: 50%;
  width: 70%;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const ReviewContainer = styled.div`
  font-size: 0.9rem;
`;
